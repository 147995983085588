@import './icons.scss';

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// RTL
//  //@import "custom/rtl/general-rtl";
//  @import "custom/rtl/bootstrap-rtl";
//  @import "custom/rtl/spacing-rtl";
//  @import "custom/rtl/float-rtl";
//  @import "custom/rtl/text-rtl";
//  @import "custom/rtl/structure-rtl";
//  @import "custom/rtl/plugins-rtl";
//  @import "custom/rtl/components-rtl";
//  @import "custom/rtl/pages-rtl";

.bg-primary {
  background-color: #5156be !important;
}

/* Add this to your CSS file */
.dark-mode .drag-container {
  background-color: #333; /* Dark background color */
}

.dark-mode .drag-item {
  background-color: #444; /* Darker background color for items */
  color: #fff; /* Light text color */
}

/* Add this to your styles */

/* Add more dark mode styles as needed */

@keyframes test {
  0% {
    animation-timing-function: ease-out;
    transform: scale(1);
    transform-origin: center center;
  }

  10% {
    animation-timing-function: ease-in;
    transform: scale(0.91);
  }

  17% {
    animation-timing-function: ease-out;
    transform: scale(0.98);
  }

  33% {
    animation-timing-function: ease-in;
    transform: scale(0.87);
  }

  45% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}
.test {
  animation: test 2s ease 0s infinite normal forwards;
}
.img-overview-dashboard {
  display: block;
}
.drag-container {
  display: flex;
  flex-direction: column;
}

.drag-item {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  // display: flex;
  // align-items: center;
}

.drag-handle {
  cursor: grab;
  // margin-right: 10px;
  display: inline-flex;
  align-items: center;
  //margin-top: 50px; /* Adjust this value as needed */
}

.dragging {
  opacity: 0.8;
  transform: scale(1.05);
  transition: opacity 0.2s, transform 0.2s;
}
.facebook-ads-card:hover {
  background-color: #f0f0f0; /* Change background color on hover */
  transition: background-color 0.3s ease-in-out; /*smooth transition effect */
}

.facebook-ads-card:hover .img-intgration-icon {
  filter: brightness(0.8);
  transition: filter 0.3s ease-in-out; /*smooth transition effect */
}

/* close button **/
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
// .search-label {
//   float: right;
// }
.react-drawer-overlay {
  z-index: 9999;
}

/* rating**/
.rating-container {
  background-color: transparent !important;
}

/* form editor **/
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}

.search-box .search-icon-search {
  font-size: 16px !important;
  position: absolute !important;
  left: 13px !important;
  top: 0 !important;
  line-height: 38px !important;
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 30px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #5156be !important;
}
.preview-thumbsnav {
  display: flex !important;
  list-style-type: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #5156be;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #5156be;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #5156be;
}

.search-box .search-icon {
  font-size: 16px;
  position: absolute;
  left: 13px;
  top: 0;
  line-height: 38px;
}
.ant-table-thead .ant-table-cell {
  background-color: #f4f2ff !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #6e6893 !important;
}

.custom-button {
  background-color: inherit;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #d9d5ec;
  color: #6e6893;
  border-radius: 2px;
  padding: 20px 20px;
  margin-right: 8px;
  box-shadow: none;
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
}

.custom-button:focus {
  background-color: '#F2F0F9';
}

.viewMetrics-button {
  background-color: #0080f9;
  padding: 13px 42px 13px 42px;
  margin-left: auto;
  font-weight: 500;
  font-size: 17px;
  border-color: #0080f9 !important;
  border-radius: 6px;
}

.custom-card {
  border: none !important;
}

.ant-table-row:hover {
  background-color: #f4f2ff !important;
}
.ant-table-cell-row-hover {
  background-color: #f4f2ff !important;
}

.custom-search-input {
  padding-left: 35px;
  background-color: #f4f2ff;
  border: none;
  box-shadow: none;
}
.custom-search-input:focus {
  border: none; /* Adjust the border color on focus */
  box-shadow: none; /* Ensure no shadow appears on focus */
  outline: none; /* Remove the outline when focused */
}
.table-border {
  border: 1px solid #d9d5ec;
  border-radius: 6px;
}
.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  margin: 0px !important;
}

hr {
  border: 0;
  height: 1px;
  background-color: #e9e9ef; /* Replace with the exact color you need */
  opacity: 1 !important; /* Override global opacity setting */
  // margin-top: 20px; /* Adjust as necessary for spacing */
}
.img-intgration-icon {
  max-width: 40px;
  height: auto;

  /* margin: 10px auto; */
}
.comingsoon-intgration {
  max-width: 100px;
  height: auto;
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.img-overview-dashboard {
  max-width: 35px;
  height: auto;
  margin-left: 1450px;
}

.alert-danger {
  color: #ea2427 !important;
  background-color: #fee4e4fc !important;
  border: none !important;
}
.mt-2-overview-dashboard {
  margin-top: 0.5rem !important;
  text-align: end;
}
i.overview-dashboard-icon {
  color: #f6f6f6;
}

.floating-label-group {
  position: relative;
  margin-bottom: 20px;
}

.floating-label-group .form-control {
  padding: 12px 12px 12px 40px;
  font-size: 17px;
  font-weight: 400;
}

.floating-label-group .floating-label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 12px;
  font-size: 17px;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  color: #a9a9a9;
}

.floating-label-group.focused .floating-label,
.floating-label-group .form-control:not(:placeholder-shown) + .floating-label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #5a5a5a;
}

.table-cell-text {
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 16px;
}

.buttonProfile {
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  color: #0080f9;
  background-color: transparent;
  border: 1px solid #0080f9;
  padding: 8px 30px;
}

.link-subscription {
  color: #584f8c;
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
}

.planStyle-subscription {
  background-color: #f7f5ff;
  border-radius: 10px;
  padding: 20px;
  font-size: 17px;
  font-weight: 500;
  color: #584f8c;
  border: 1px solid #d9d5ec;
}
.ant-table-wrapper {
  border: 1px solid #d9d5ec;
  border-radius: 4px;
}

.editbutton {
  background-color: #38a938 !important;
  color: white !important;
  margin-right: 10px;
  width: 88px;
  height: 40px;
  font-size: 17px;
  font-weight: medium;
  border: none;
}

.maintitle {
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  color: #ffffff;
}
.subtitle {
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-overflow: ellipsis;
}
.deletebutton {
  background-color: #ffe0e0 !important;
  color: #d30000 !important;
  width: 88px;
  height: 40px;
  font-size: 17px;
  font-weight: medium;
  border: none;
}

.savebutton {
  background-color: #0080f9 !important;
  color: white !important;
  margin-right: 10px;
  width: 88px;
  height: 40px;
  font-size: 17px;
  font-weight: medium;
  border: none;
}

.cancelbutton {
  background-color: rgba(0, 128, 249, 0.31) !important;
  color: white !important;
  width: 88px;
  height: 40px;
  font-size: 17px;
  font-weight: medium;
  border: none;
}

.ant-table table {
  border-collapse: collapse !important; /* This will make sure the borders collapse together */
}

.editable-row {
  border: 2px solid #0080f9; /* Apply a border to the entire row */
  border-radius: 8px;
}

.pnlTabContainer {
  width: 91%;
  padding-right: 30px;
}

::-webkit-scrollbar {
  width: 10px; /* Adjust scrollbar width */
}

::-webkit-scrollbar-thumb {
  background-color: #04044c; /* Thumb color (as shown in the Figma design) */
  border-radius: 10px; /* Make the scrollbar rounded */
}

::-webkit-scrollbar-track {
  background-color: #f4f2ff; /* Track color, matching the Figma design */
  border-radius: 10px; /* Ensure the track is rounded as well */
}
body::-webkit-scrollbar {
  height: 3px !important; /* height of horizontal scrollbar ← You're missing this */
  width: 3px !important;
}

.cards {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  width: 120% !important;
  margin-top: 20px;
  flex-wrap: wrap;
}

.cards > div {
  margin-right: 26px;
  border-radius: 14px;
}

.cardsContainer {
  display: flex;
  /* align-items: center; */
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.dashboardMinorCardItem {
  max-width: 22%;
  margin-right: 12px;
  margin-bottom: 15px;
}

////////
/// pricing
///
///
.pricing-page {
  text-align: center;
  padding: 20px;
}

.pricing-header {
  text-align: left; /* Align the text to the left */
  margin-bottom: 25px;
}

.pricing-header h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #000;
}

.pricing-header p {
  font-size: 17px;
  color: #25213b;
}

.currency-toggle button {
  border: none;
  background-color: #f5f5ff;
  color: #000;
  padding: 10px 20px;
  border-radius: 6px;
  margin: 10px;
}

.billing-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 70px;
}

.billing-toggle .ant-btn-group > .ant-btn-primary {
  background-color: #1890ff;
  color: white;
}

.billing-toggle .ant-btn-group > .ant-btn-default {
  color: #000000;
  background-color: white;
}

.billing-toggle span {
  font-weight: 500;
}

.billing-toggle button {
  margin: 4px 4px;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: none;
}

.billing-toggle .active {
  background-color: #0064e0;
  color: white;
}

.plan-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.plan-card {
  border: 1px solid #0064e0;
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  background-color: #f5f5ff;
}

.plan-card.popular {
  border: 2px solid #0064e0;
  background-color: white;
}

.plan-card h2 {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
}

.plan-cards .plan-card p {
  font-size: 14px;
  color: #666;
  text-align: left;
}

.plan-cards .plan-card .price {
  font-size: 32px;
  font-weight: bold;
  color: #000;
}

.plan-cards .plan-card button {
  background-color: #0064e0;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  height: 42px;
  margin-bottom: 20px;
}

.features .descriptionheader {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-top: 40px;
}

.features p {
  margin: 16px 0;
  font-size: 14px;
  color: #555;
}

.features p.disabled {
  color: #ccc;
}

.features i {
  color: #0064e0;
  margin-right: 10px;
}

.support-info {
  margin-top: 40px;
  font-size: 12px;
  color: #666;
  text-align: left;
}

.support-info a {
  color: #0064e0;
  text-decoration: none;
  font-weight: bold;
}

.popular-badge {
  border: 1px solid #0064e0;
  background-color: #0064e0;
  color: #fff;
  border-radius: 6px 6px 0 0;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 101%;
}
.popular-badge-current-plan {
  border: 1px solid #3ecf8e;
  background-color: #3ecf8e;
  color: #fff;
  border-radius: 6px 6px 0 0;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 101%;
}

.noMargin {
  margin-bottom: 0px !important;
}
.bg-user-message{
  background-color: #E6F3FF;
  color: #0080F9;
  border-radius: 20px;
 padding: 0px 6px;
}
.bg-color-chat-icon{
  color: #59527A;
  
}
.no-hover:hover {
  background-color: transparent !important;
  color: #fff !important;
}




// start bubble chat 
.chat-bubble {
  // background-color:#E6F8F1;
  padding:6px 11px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display:inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96 ; //rgba(20,105,69,.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:#6CAD96; // rgba(20,105,69,.7);
  }
  28% {
    transform: translateY(-7px);
    background-color:#9ECAB9; //rgba(20,105,69,.4);
  }
  44% {
    transform: translateY(0px);
    background-color: #B5D9CB; //rgba(20,105,69,.2);
  }
}

//end bubble chat 


.custom-input::placeholder {
  color: #0080F9 !important; /* Change placeholder color */
  opacity: 1; /* Ensure full visibility */
}
.dropdown-custom-chat button{
color: #fff !important;
border: none !important;
background-color: #04044C !important;
}


.rdrDateDisplayWrapper{
  background-color: #fff !important;
}

.ant-typography {

  color: #fff !important;
}
.ant-conversations-item{
  padding-inline-start: 0px !important;
}